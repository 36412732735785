<template>
    <div class="agentManage">
        <div class="searchForm_box">
            <el-form
                    :inline="true"
                    size="small"
                    :model="searchForm"
                    class="demo-form-inline"
            >
                <el-form-item label="商品名稱">
                    <el-input
                            v-model="searchForm.goods_name"
                            placeholder="請輸入商品名稱"
                    ></el-input>
                </el-form-item>
                <el-form-item label="商品編碼">
                    <el-input
                            v-model="searchForm.goods_no"
                            placeholder="請輸入商品編碼"
                    ></el-input>
                </el-form-item>
                <el-form-item label="代理商">
                    <el-select v-model="agentDefaultId">
                        <el-option
                                v-for="item in agentList"
                                :key="item.agent_id"
                                :label="item.agent_name"
                                :value="item.agent_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="list" border class="table" v-loading="tableLoading">
<!--            <el-table-column width="100" label="ID" prop="goods_id"></el-table-column>-->
            <el-table-column label="商品圖片" prop="img" width="90">
                <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px" :src="scope.row.img != '' && scope.row.img != null ? scope.row.img : require('@/assets/image/default.jpg')" fit="contain">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column width="500" label="商品名稱" prop="goods_name"></el-table-column>
            <el-table-column label="商品分類" prop="category_name"></el-table-column>
            <el-table-column label="條形碼" prop="goods_no"></el-table-column>
            <el-table-column label="價格" prop="price"></el-table-column>
            <el-table-column label="進價" prop="cost_price"></el-table-column>
        </el-table>
        <!-- 分頁 -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[14, 20, 30, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
        >
        </el-pagination>
    </div>
</template>

<script>
    import {listGoods, listAgent} from "@/api/agentGoods";

    export default {
        name: "agentManage",
        data() {
            return {
                // 表格loading
                tableLoading: false,
                searchForm: {
                    goods_name: "",
                    goods_no: "",
                    agent_id: "",
                },
                page: 1,
                pageSize: 50,
                total: 0,
                list: [],
                agentList: [],
                agentDefaultId: ''
            };
        },
        created() {
            this.getAgent();
            this.getList();
        },
        mounted() {
            // this.getRoleOption();
        },
        methods: {
            search(){
                this.page = 1;
                this.getList();
            },
            getList() {
                let params = {
                    page: this.page,
                    pageSize: this.pageSize,
                    goods_name: this.searchForm.goods_name,
                    goods_no: this.searchForm.goods_no,
                    agent_id: this.agentDefaultId,
                }
                this.tableLoading = true
                listGoods(params).then(res => {
                    this.tableLoading = false
                    this.list = res.data.list
                    this.total = res.data.count
                    this.agentDefaultId = res.data.agent_id
                });
            },
            getAgent() {
                listAgent({ page: 1,pageSize: 99999,}).then(res => {
                    this.agentList = res.data.list
                });
            },
            // 分頁
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getList();
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/agentManage.scss";
</style>