import http from "../utils/request"


//查询商品列表
export function listGoods(data) {
    return http({
        url: '/admin/goods/list',
        method: 'post',
        data: data
    })
}

// 供应商列表
export function listAgent(data) {
    return http({
        url: '/admin/agent/list',
        method: 'post',
        data: data
    })
}
